import { Atom, SHARED_VALIDATIONS, helper } from ":mods";

export function createContactInfoInputs() {
  const FirstName = Atom.Form.createInput("", {
    required: true,
    validators: (value) => {
      const err = SHARED_VALIDATIONS.person_name.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const LastName = Atom.Form.createInput("", {
    required: true,
    validators: (value) => {
      const err = SHARED_VALIDATIONS.person_name.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const Gender = Atom.Form.createInput("", {
    required: true,
  });
  const DOB = Atom.Form.createInput("", {
    required: true,
  });

  const MobileNo = Atom.Form.createPhoneInput("", {
    required: true,
    validators: (value) => {
      const err = SHARED_VALIDATIONS.phone_number.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const CountryCode = Atom.Form.createInput("" as string, {
    required: true,
  });
  const Actions = Atom.Form.createFormActions({
    FirstName,
    LastName,
    Gender,
    DOB,
    MobileNo,
    CountryCode,
  });
  return {
    Actions,
    FirstName,
    LastName,
    Gender,
    DOB,
    MobileNo,
    CountryCode,
  };
}
